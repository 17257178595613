:root {
  --colbg: #0208f6;
  --col1: rgb(255, 0, 0);
  --col2: #f5d60f;
  --col3: #8c00ff;
}

/* ============= Styling ========= */
#header, #footer {
  background-color: rgba(0, 0, 0, 0.87);
}

#page-container {
  position: relative;
  min-height: 100vh;
}

/*#content-wrap{*/
/*  -ms-overflow-style: none;  !* IE and Edge *!*/
/*  scrollbar-width: none;  !* Firefox *!*/
/*}*/
/*!* Hide scrollbar for Chrome, Safari and Opera *!*/
/*#content-wrap::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.msg {
  border-radius: 10px;
  padding: 10px;
}

.msg.right {
  background-color:#CCC;
  float: right;
}

.msg.left {
  background-color: #0f64e3;
  color: white;
}

#content-wrap {
  padding-bottom: 60px;    /* Footer height */
}

#footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;            /* Footer height */
}

.logo-block {
  padding: 15px; /* Padding around the logo */
  border-radius: 12px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px; /* Adds space on the left and right sides of the box */
  height: 120px; /* Increased block height to better accommodate taller logos */
}

.logo-img {
  max-height: 100%; /* Allow the logo to take up the full height of the block */
  max-width: 100%; /* Ensure the logo does not exceed the block’s width */
  height: auto; /* Maintain the aspect ratio */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensures the entire logo fits within the block */
}

.custom-accordion .accordion-item {
  background-color: #3B2F2F;
  color: white;
}

.custom-accordion .accordion-button {
  background-color: #3B2F2F !important;
  color: white !important;
  padding: 15px !important;
  border: none !important;
}

.custom-accordion-button:focus {
  box-shadow: none; /* Remove the focus outline */
}

.custom-accordion-body {
  background-color: #3B2F2F; /* Deep brown background */
  color: white; /* White text */
  border-radius: 20px; /* Optional: add slight rounding for the inside content */
}

#title {
  color: white;
}

html, body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  font-family: 'Josefin Sans', sans-serif;
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0;
}

.card {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 12px !important;
}

.sharp-corners{
  border-radius: 0 !important;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.aframe{
  height: 100vh;
}

.toast {
  background-color: rgba(255, 255, 255, 0.98) !important;
}

nav{
  white-space: nowrap;
}

/*Solution for poor sizing in a couple of the mobile browsers*/
@media (max-width: 365px) {
  .nav-item{
    width: 25%;
  }
}




/* ============= bg ========= */
.bg-main {
  overflow-x: hidden;
  background-size: 100% 100%;
  background: linear-gradient(var(--colbg), var(--colbg)) repeat;
}
.bg-blue {
  background-size: 100% 100%;
  background: linear-gradient(var(--col1), var(--col1)) repeat;
}
.bg-red {
  background-size: 100% 100%;
  background: linear-gradient(var(--col2), var(--col2)) repeat;
}
.bg-green {
  background-size: 100% 100%;
  background: linear-gradient(var(--col3), var(--col3)) repeat;
}
.sec-blue {
  background-size: 100% 100%;
  background: linear-gradient(var(--col1), var(--col1)) repeat;
}
.sec-red {
  background-size: 100% 100%;
  background: linear-gradient(var(--col2), var(--col2)) repeat;
}
.sec-green {
  background-size: 100% 100%;
  background: linear-gradient(var(--col3), var(--col3)) repeat;
}

/* ============= Camo ========= */
.camo {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position:fixed;
}

#camoL1{
  background: url("elements/camo/camo l1.png") repeat;
}
#camoL2{
  background: url("elements/camo/camo l2.png") repeat;
}
#camoL3{
  background: url("elements/camo/camo l3.png") repeat;
}
